<template>
  <nav v-if="visibleOnInvite" class="navigation">
    <ul class="navigation__list">

       <li class="navigation__item" v-for="(navigation, index) in navigator"  :key="index">
          
        <router-link
            v-if="checkNavigation(navigation)"
            :to="navigation.navigationPath"
            class="navigation__link"
            :class="{ white: whiteColor}"
            :data-cy="navigation.navigationName"   
            ><a-tooltip class="link-tooltip" >
              <!-- <template #title v-if="!navigation.isComingSoon">{{ navigation.navigationName }}</template> -->
              <template v-if="navigation.isComingSoon" #title>Coming Soon</template>
              {{ navigation.navigationName }}
        </a-tooltip>
        </router-link>

           <a v-else-if="navigation.checkAuthentication && !isAuthenticatedUser || navigation.isComingSoon" :data-cy="navigation.navigationName" class="navigation__link" :class="{ white: whiteColor}">
            <a-tooltip class="link-tooltip">
            <!-- <template #title v-if="!navigation.isComingSoon">{{ navigation.navigationName }}</template> -->
              <template v-if="navigation.isComingSoon" #title>Coming Soon</template>
              {{ navigation.navigationName }}
            </a-tooltip>
          </a>
        
          <router-link :to="navigation.navigationPath" v-if="navigation.children.length > 0" :data-cy="navigation.navigationName" class="navigation__link dropdown-link" :class="{ white: whiteColor }">
            <a-dropdown>
              <p class="ant-dropdown-link" @click="checkDiscover(navigation.navigationPath)">{{ navigation.navigationName }}</p>
              <template #overlay>
                <a-menu class="navbar-dropdown">
                  <a @click="checkDiscover(navigation.navigationPath)">
                  <a-menu-item v-for="(child) in navigation.children" :key="child.key">
                      <a-tooltip class="link-tooltip" placement="right">
                       <!-- <template #title v-if="!child.isComingSoon" >{{ child.navigationName }}</template> -->
                        <template v-if="child.isComingSoon" #title>Coming Soon</template>
                        {{ child.navigationName }}
                      </a-tooltip>
                  </a-menu-item>
                  </a>
                  <!-- <a-menu-item key="1">
                    <a-tooltip class="link-tooltip" placement="right">
                      <template #title>Coming soon</template>
                      Topic Heatmap *
                    </a-tooltip></a-menu-item>

                  <a-menu-item key="2"
                    ><a-tooltip class="link-tooltip" placement="right">
                      <template #title>Coming soon</template>
                      Topic Timeline *
                    </a-tooltip></a-menu-item
                  >
                  <a-menu-item key="2"
                    ><a-tooltip class="link-tooltip" placement="right">
                      <template #title>Coming soon</template>
                      Source Analytics *
                    </a-tooltip></a-menu-item
                  > -->
                </a-menu>
              </template>
            </a-dropdown>
          </router-link>
        
        
        </li>
  

      <!-- <li class="navigation__item">
        <router-link
          to="/discover"
          class="navigation__link"
          :class="{ white: whiteColor }"
          ><a-tooltip class="link-tooltip">
            <template #title>Discover</template>
            Discover
          </a-tooltip></router-link
        >
      </li>

      <li class="navigation__item">
        <a class="navigation__link" :class="{ white: whiteColor }">
          <a-tooltip class="link-tooltip">
            <template #title>Coming soon</template>
            Publish*
          </a-tooltip>
        </a>
      </li> -->


      <!-- prevoius comment -->

      <!-- <li class="navigation__item">
        <router-link to="/intelligence-dashboard" class="navigation__link" :class="{'white': whiteColor}">News Intelligence</router-link>
      </li> -->

      <!-- prevoius comment -->

      <!-- <li class="navigation__item">
        <router-link
         v-if="isAuthenticatedUser"
          to="/engage"
          class="navigation__link"
          :class="{ white: whiteColor }"
          ><a-tooltip class="link-tooltip">
            <template #title>Impact</template>
            Impact
          </a-tooltip></router-link
        >
        <a v-else class="navigation__link" :class="{ white: whiteColor }">
          <a-tooltip class="link-tooltip">
            <template #title>Impact</template>
            Impact
          </a-tooltip>
        </a>
      </li>

      <li class="navigation__item">
        <a class="navigation__link" :class="{ white: whiteColor }">
          <a-dropdown>
            <p class="ant-dropdown-link" @click.prevent>News Intelligence *</p>
            <template #overlay>
              <a-menu class="navbar-dropdown">
                <a-menu-item key="1"
                  ><a-tooltip class="link-tooltip" placement="right">
                    <template #title>Coming soon</template>
                    Topic Heatmap *
                  </a-tooltip></a-menu-item
                >
                <a-menu-item key="2"
                  ><a-tooltip class="link-tooltip" placement="right">
                    <template #title>Coming soon</template>
                    Topic Timeline *
                  </a-tooltip></a-menu-item
                >
                <a-menu-item key="2"
                  ><a-tooltip class="link-tooltip" placement="right">
                    <template #title>Coming soon</template>
                    Source Analytics *
                  </a-tooltip></a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </a>
      </li> -->

    </ul>
  </nav>
</template>
<script>


export default {
  props: {
    whiteColor: Boolean,
    visibleOnInvite: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isAuthenticatedUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  
     navigator() { 
      return this.navigationList
     }
  },

  data: () => ({
          
    navigationList: [
      {
        navigationName: "Discover",
        navigationPath: "/discover",
        isComingSoon: false,
        checkAuthentication: false,
        children: [
          {
            key: 1,
            navigationName: "Discover",
            isComingSoon: false,
          },
          {
            key: 2,
            navigationName: "Topic Heatmap *",
            isComingSoon: true,
          },
          {
            key: 3,
            navigationName: "Topic Timeline *",
            isComingSoon: true,
          },
          {
            key: 4,
            navigationName: "Source Analytics *",
            isComingSoon: true,
          }
        ]
      },
      {
        navigationName: "Network Feed",
        navigationPath: "/network-feed",
        isComingSoon: false,
        checkAuthentication: false,
        children: []
      },
      /* {
        navigationName: "Publish",
        navigationPath: "",
        isComingSoon: true,
        checkAuthentication: false,
        children: []
      },
      {
        navigationName: "Impact",
        navigationPath: "/engage",
        isComingSoon: true,
        checkAuthentication: true,
        children: []
      },
      */
    ]
    

  }),

  methods: {
    checkNavigation(activeNavigation) { 
      if (activeNavigation.children.length === 0) {
        if (activeNavigation.checkAuthentication && !this.isAuthenticatedUser)
          return false
        else if (activeNavigation.isComingSoon)
        return false

        return true
      }
    },
    checkDiscover(navigator) {
      if(navigator === '/discover') {
        this.$router.push(navigator)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  margin-left: 2rem;
  width: max-content;
  @include respond(phone-large) {
    margin-left: 1rem;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .router-link-active {
      position: relative;
      font-family: $font-secondary-bold;
      display: inline-block;
      &::after {
        content: "";
        width: 2.5rem;
        height: 0.4rem;
        background-color: #000;
        position: absolute;
        bottom: -0.8rem;
        left: 0;
        opacity: 1;
      }
    }
  }
  &__item {
    margin: 0 2rem;
    letter-spacing: 0.05rem;
    line-height: 0px;
    @include respond(laptop-large) {
      margin: 0 1.5rem;
    }
    @include respond(laptop-small) {
      margin: 0 1.5rem;
    }
    .ant-dropdown-link {
      margin-bottom: 0;
    }
  }

  &__link {
    font-size: 1.44rem;
    font-family: $font-secondary;
    color: $color-black;
    opacity: 0.8;
    line-height: 2rem;
    @include respond(laptop-large) {
      font-size: 1.6rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  
  .white {
    color: $color-white;
  }
  .white.router-link-active {
    &::after {
      background-color: $color-white;
    
    }
  }
}
</style>
